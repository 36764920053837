import { useQuery } from "react-query";
import { getMeta } from "~/services/api";

export const useMeta = () => {
  return useQuery({
    queryKey: ["meta"],
    queryFn: () => getMeta(),
  });
};

export const useIsProduction = (defaultValue = true): boolean => {
  // true as a safe default to prevent showing dev-only features during loading
  const { data: { is_production = defaultValue } = {} } = useMeta();
  return is_production;
};
