export const SupportIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_957_28134)">
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17V17.01"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 13.4999C11.9816 13.1753 12.0692 12.8535 12.2495 12.5829C12.4299 12.3124 12.6933 12.1078 13 11.9999C13.3759 11.8562 13.7132 11.6272 13.9856 11.3309C14.2579 11.0346 14.4577 10.6791 14.5693 10.2925C14.6809 9.90588 14.7013 9.49861 14.6287 9.10279C14.5562 8.70696 14.3928 8.33337 14.1513 8.01144C13.9099 7.6895 13.597 7.42801 13.2373 7.24754C12.8776 7.06707 12.4809 6.97256 12.0785 6.97145C11.6761 6.97033 11.2789 7.06264 10.9182 7.24111C10.5576 7.41958 10.2432 7.67933 10 7.99992"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_957_28134">
        <rect width="24" height="24" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
