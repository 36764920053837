import { useCallback, useState } from "react";
import Box from "@mui/joy/Box";
import CssBaseline from "@mui/joy/CssBaseline";
import { Sidebar } from "~/components/super-admin/layout/sidebar";
import { useWindowSize } from "~/hooks/use-window-size";
import { useJoyTheme } from "~/hooks/use-joy-theme";
import { Breadcrumbs } from "../breadcrumbs";
import { Head } from "../head";

enum SidebarState {
  UNTOUCHED,
  FORCED_OPEN,
  FORCED_CLOSED,
}

export const Layout = ({ children }) => {
  const theme = useJoyTheme();
  const { width: windowWidth } = useWindowSize(200);

  const isScreenMediumOrBigger = windowWidth >= theme.breakpoints.values.md;
  const [sidebarState, setSidebarState] = useState<SidebarState>(SidebarState.UNTOUCHED);

  const toggleSidebar = useCallback(() => {
    setSidebarState((sidebarState) => {
      if (
        sidebarState === SidebarState.FORCED_OPEN ||
        sidebarState === SidebarState.UNTOUCHED
      ) {
        return SidebarState.FORCED_CLOSED;
      }

      return SidebarState.FORCED_OPEN;
    });
  }, []);

  const isSidebarOpen =
    sidebarState === SidebarState.FORCED_OPEN ||
    (sidebarState === SidebarState.UNTOUCHED && isScreenMediumOrBigger);

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        maxWidth: "1650px",
        position: "relative",
      }}
    >
      <CssBaseline />
      <Sidebar isOpen={isSidebarOpen} onToggle={toggleSidebar} />
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          p: 3.5,
          maxWidth: `calc(100% - ${
            isSidebarOpen
              ? theme.sidebar.width.open + "px"
              : theme.sidebar.width.closed + "px"
          })`,
        }}
      >
        <Head />
        <Breadcrumbs />
        {children}
      </Box>
    </Box>
  );
};
