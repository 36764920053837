import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import App from "./App";
import { Loading } from "./components";
import { ReRenderProvider } from "./context/re-render";
import "./i18n";
import store from "./redux/store";
import { queryClient } from "./services/query-client";

createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Suspense fallback={<Loading fullScreen />}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ReRenderProvider>
            <App />
          </ReRenderProvider>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Suspense>
  </React.StrictMode>
);
