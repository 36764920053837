import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";
import * as Sentry from "@sentry/react";
import isNil from "lodash/isNil";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Navigate, useRouteError } from "react-router-dom";
import { signOut } from "~/helpers";
import DefaultLayout from "~/layouts/default-layout";
import { currentUserSelector } from "~/redux/selectors";
import { useOriginalUrl } from "~/routes/use-original-url";
import { checkIfNetworkError, isDevEnvironment } from "~/utils";

type ErrorBoundaryProps = {
  redirectUnAuthorized?: boolean;
};

// TODO: probably need design
export function AdminError({ error, isInvalidPath = false }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSignOut = async () => {
    await signOut();
    navigate("/");
  };
  const code = error.status || error.response?.status;
  const isDevEnv = isDevEnvironment();
  const isNetworkError = checkIfNetworkError(error);

  let message = t([
    `errors.statusCodes.${error.status || error.response?.status || 500}.title`,
  ]);
  let description = t([
    `errors.statusCodes.${error.status || error.response?.status || 500}.description`,
  ]);
  let stack = "";
  if (isDevEnv) {
    message = isNetworkError ? "Network error" : error.message;
    description = error.description;
    stack = error.stack;
  }

  return (
    <Box
      sx={{
        m: "auto",
        width: "100%",
        maxWidth: "36rem",
        textAlign: "center",
        color: "text.secondary",
      }}
    >
      {code && (
        <Typography
          level="title-lg"
          sx={{
            mb: 6,
            color: "text.secondary",
          }}
        >
          {code}(k)
        </Typography>
      )}
      <Typography
        level="title-lg"
        sx={{
          mb: 3,
        }}
      >
        {message || t([`errors.statusCodes.${code}.title`, "errors.genericError.title"])}
      </Typography>

      {stack ? (
        <Box
          sx={{
            textAlign: "left",
          }}
        >
          {stack}
        </Box>
      ) : (
        <Typography
          sx={{
            mb: 6,
            fontSize: "0.875rem",
          }}
        >
          {isNil(description)
            ? t([
                `errors.statusCodes.${code}.description`,
                "errors.genericError.description",
              ])
            : description}
        </Typography>
      )}
      {!isInvalidPath && (
        <Box
          sx={{
            mt: 8,
            display: "flex",
            justifyContent: "center",
            gap: 5,
          }}
        >
          <Button variant="outlined" onClick={onSignOut}>{t("common:general.signout")}</Button>
        </Box>
      )}
    </Box>
  );
}

export const AdminErrorBoundary: FC<ErrorBoundaryProps> = ({
  redirectUnAuthorized = false,
}) => {
  const error: any = useRouteError();
  const user: { [key: string]: any } = useSelector(currentUserSelector);
  const { url: loginUrlWithOriginalUrl } = useOriginalUrl({ pathname: "/login" });

  useEffect(() => {
    if (error.response?.status !== 401) {
      Sentry.captureException(error);
    }
  }, [error]);

  if (redirectUnAuthorized && !user.id) {
    return <Navigate to={loginUrlWithOriginalUrl} />;
  }

  return (
    <DefaultLayout showProgressCircle={false}>
      <AdminError error={error} />
    </DefaultLayout>
  );
};
