import formsPlugin from "@tailwindcss/forms";

const config = {
  content: ["./src/**/*.{js,jsx,ts,tsx}", "./index.html"],
  theme: {
    extend: {
      fontFamily: {
        sans: ["Centra", "Helvetica", "Arial", "sans-serif"],
        centra: ["Centra", "Helvetica", "Arial", "sans-serif"],
        "relative-faux": ["Relative Faux", "Centra", "Helvetica", "Arial", "sans-serif"],
      },
      colors: {
        primary: {
          main: "var(--color-primary-main)",
          light: "var(--color-primary-light)",
          light_1: "var(--color-primary-light-1)",
          light_2: "var(--color-primary-light-2)",
          dark: "var(--color-primary-dark)",
          dark_1: "var(--color-primary-dark-1)",
          dark_2: "var(--color-primary-dark-2)",
        },
        secondary: {
          main: "var(--color-secondary-main)",
          light: "var(--color-secondary-light)",
          light_1: "var(--color-secondary-light-1)",
          light_2: "var(--color-secondary-light-2)",
          light_3: "var(--color-secondary-light-3)",
          dark: "var(--color-secondary-dark)",
        },
        tertiary: {
          main: "var(--color-tertiary-main)",
          light: "var(--color-tertiary-light)",
        },
        text: {
          primary: {
            main: "var(--text-primary-main)",
            dark: "var(--text-primary-main)",
            dark_1: "var(--text-primary-main)",
            dark_2: "var(--text-primary-main)",
          },
          secondary: {
            main: "var(--text-secondary-main)",
            light: "var(--text-secondary-light)",
            light_1: "var(--text-secondary-light-1)",
            light_2: "var(--text-secondary-light-2)",
          },
          tertiary: {
            main: "var(--text-tertiary-main)",
            light: "var(--text-tertiary-light)",
          },
        },
        layout: {
          bg: "var(--layout-bg)",
        },
        success: {
          main: "var(--color-success-main)",
          light: "var(--color-success-light)",
          light_1: "var(--color-success-light-1)",
          dark: "var(--color-success-dark)",
        },
        chip: {
          connected: {
            text: "var(--chip-connected-text)",
          },
          success: {
            text: "var(--chip-success-text)",
          },
          warning: {
            text: "var(--chip-warning-text)",
            bg: "var(--chip-warning-bg)",
          },
        },
        error: {
          main: "var(--pen-error-red)",
          light: "var(--pen-error-red-light)",
        },
        button: {
          primary: {
            bg: "var(--button-primary-bg)",
            bg_hovered: "var(--button-primary-bg-hovered)",
            bg_disabled: "var(--button-primary-bg-disabled)",
            text: "var(--button-primary-text)",
          },
          secondary: {
            bg: "var(--button-secondary-bg)",
            bg_hovered: "var(--button-secondary-bg-hovered)",
            bg_disabled: "var(--button-secondary-bg-disabled)",
            border: "var(--button-secondary-border)",
            border_hovered: "var(--button-secondary-border-hovered)",
            border_disabled: "var(--button-secondary-border-disabled)",
            text: "var(--button-secondary-text)",
            text_hovered: "var(--button-secondary-text-hovered)",
            text_disabled: "var(--button-secondary-text-disabled)",
          },
          tertiary: {
            bg: "var(--button-tertiary-bg)",
            bg_hovered: "var(--button-tertiary-bg-hovered)",
            bg_disabled: "var(--button-tertiary-bg-disabled)",
            border: "var(--button-tertiary-border)",
            border_hovered: "var(--button-tertiary-border-hovered)",
            border_disabled: "var(--button-tertiary-border-disabled)",
            text: "var(--button-tertiary-text)",
            text_hovered: "var(--button-tertiary-text-hovered)",
            text_disabled: "var(--button-tertiary-text-disabled)",
          },
          accent: {
            bg: "var(--button-accent-bg)",
            bg_hovered: "var(--button-accent-bg-hovered)",
            bg_disabled: "var(--button-accent-bg-disabled)",
            border: "var(--button-accent-border)",
            border_hovered: "var(--button-accent-border-hovered)",
            border_disabled: "var(--button-accent-border-disabled)",
            text: "var(--button-accent-text)",
            text_hovered: "var(--button-accent-text-hovered)",
            text_disabled: "var(--button-accent-text-disabled)",
          },
          accent_2: {
            bg: "var(--button-accent-2-bg)",
            bg_hovered: "var(--button-accent-2-bg-hovered)",
            bg_disabled: "var(--button-accent-2-bg-disabled)",
            border: "var(--button-accent-2-border)",
            border_hovered: "var(--button-accent-2-border-hovered)",
            border_disabled: "var(--button-accent-2-border-disabled)",
            text: "var(--button-accent-2-text)",
            text_hovered: "var(--button-accent-2-text-hovered)",
            text_disabled: "var(--button-accent-2-text-disabled)",
          },
          link: {
            text: "var(--button-link-text)",
            text_hovered: "var(--button-link-text-hovered)",
            text_disabled: "var(--button-link-text-disabled)",
          },
        },
        banner: {
          bg: "var(--banner-bg)",
          text: "var(--banner-text)",
        },
        paper: {
          bg: "var(--paper-bg)",
        },
        navbar: {
          bg: "var(--navbar-bg)",
          bg_selected: "var(--navbar-bg-selected)",
          text: "var(--navbar-text)",
          text_hovered: "var(--navbar-text-hovered)",
          text_selected: "var(--navbar-text-selected)",
        },
        areaChart: {
          bg: "var(--area-chart-bg)",
          0: {
            fill: "var(--area-chart-0-fill)",
            stroke: "var(--area-chart-0-stroke)",
          },
          1: {
            fill: "var(--area-chart-1-fill)",
            stroke: "var(--area-chart-1-stroke)",
          },
          2: {
            fill: "var(--area-chart-2-fill)",
            stroke: "var(--area-chart-2-stroke)",
          },
          3: {
            fill: "var(--area-chart-3-fill)",
            stroke: "var(--area-chart-3-stroke)",
          },
          4: {
            fill: "var(--area-chart-4-fill)",
            stroke: "var(--area-chart-4-stroke)",
          },
          circle: {
            fill: "var(--area-chart-circle-fill)",
          },
        },
        footer: {
          logo: "var(--footer-logo)",
        },
        "pen-hay-01": "var(--pen-hay-01)",
        "pen-white-dimmed": "var(--pen-white-dimmed)",
        "pen-pine-01": "var(--pen-pine-01)",
        "pen-pine-02": "var(--pen-pine-02)",
        "pen-pine-03": "var(--pen-pine-03)",
        "pen-pine-04": "var(--pen-pine-04)",
        "pen-pine-05": "var(--pen-pine-05)",
        "pen-pine-06": "var(--pen-pine-06)",
        "pen-pine-07": "var(--pen-pine-07)",
        "pen-pine-08": "var(--pen-pine-08)",
        "pen-mauve-01": "var(--pen-mauve-01)",
        "pen-mauve-02": "var(--pen-mauve-02)",
        "pen-mauve-03": "var(--pen-mauve-03)",
        "pen-mauve-04": "var(--pen-mauve-04)",
        "pen-mauve-05": "var(--pen-mauve-05)",
        "pen-mauve-06": "var(--pen-mauve-06)",
        "pen-mauve-07": "var(--pen-mauve-07)",
        "pen-mauve-08": "var(--pen-mauve-08)",
        "pen-ash-01": "var(--pen-ash-01)",
        "pen-ash-02": "var(--pen-ash-02)",
        "pen-ash-03": "var(--pen-ash-03)",
        "pen-ash-04": "var(--pen-ash-04)",
        "pen-ash-05": "var(--pen-ash-05)",
        "pen-ash-06": "var(--pen-ash-06)",
        "pen-ash-07": "var(--pen-ash-07)",
        "pen-ash-08": "var(--pen-ash-08)",
        "pen-marigold-02": "var(--pen-marigold-02)",
        "pen-marigold-03": "var(--pen-marigold-03)",
        "pen-marigold-04": "var(--pen-marigold-04)",
        "pen-marigold-05": "var(--pen-marigold-05)",
        "pen-marigold-07": "var(--pen-marigold-07)",
        "pen-marigold-08": "var(--pen-marigold-08)",
        "pen-carolina-03": "var(--pen-carolina-03)",
        "pen-carolina-04": "var(--pen-carolina-04)",
        "pen-carolina-05": "var(--pen-carolina-05)",
        "pen-carolina-06": "var(--pen-carolina-06)",
        "pen-carolina-08": "var(--pen-carolina-08)",
      },
      fontWeight: {
        thin: "100",
      },
      zIndex: {
        "-1": "-1",
      },
      scale: ["focus", "focus-within"],
      underlineOffset: {
        default: "1px",
        small: "2px",
        medium: "4px",
        large: "8px",
      },
      padding: {
        7.5: "1.875rem",
      },
      margin: {
        7.5: "1.875rem",
      },
      fontSize: {
        xxs: "0.625rem",
        "2.5xl": "1.75rem",
        "3.5xl": "2rem",
        "4.5xl": "2.5rem",
      },
      width: {
        "13/25": "52%",
        "14/25": "55%",
        22: "5.5rem",
        "3/10": "30%",
        62: "15.5rem",
      },
      height: {
        max: "max-content",
      },
      maxWidth: (theme) => ({
        tiny: "25.625rem",
        38: "157px",
        400: "400px",
        468: "468px",
        874: "874px",
        1440: "1440px",
        "2.5xl": "44rem",
        ...theme("spacing"),
      }),
      minWidth: (theme) => ({
        22: "5.5rem",
        38: "9.5rem",
        ...theme("spacing"),
      }),
      inset: {
        0.25: "0.0625rem",
        0.5: "0.125rem",
      },
      minHeight: (theme) => ({
        ...theme("spacing"),
      }),
      lineHeight: {
        2: "0.625rem",
        3.5: "0.9375rem",
        4.5: "1.125rem",
        19: "1.1875rem",
        16: "4rem",
      },
      boxShadow: {
        xs: "0.5px 0.5px 2px #9A7F37",
        sm: "0px 2px 4px rgba(0, 0, 0, 0.25)",
      },
      borderRadius: {
        "4xl": "28px",
        100: "100px",
        50: "50px",
        20: "20px",
        10: "10px",
      },
      gridTemplateColumns: {
        "min-content": "min-content auto",
        "auto-fit": "repeat(auto-fit, minmax(12.5rem, 1fr))",
        "auto-fill": "repeat(auto-fill, minmax(12.5rem, 1fr))",
      },
      spacing: {
        5.5: "22px",
        1.25: "5px",
        "15px": "15px",
        "29px": "29px",
        "61px": "61px",
        468: "468px",
        25: "6.25rem",
      },
      screens: {
        xs: "585px",
        xmd: "920px",
      },
    },
  },
  plugins: [formsPlugin],
};

export default config;
