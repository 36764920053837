import { QueryClient, useQuery } from "react-query";
import { openapi } from "~/services/openapi-client";
import { operations } from "~/types/openapi-schema";

type FetchParameters =
  operations["get_alert_group_detail_api_v1_admin_alert_groups__alert_type_id__get"]["parameters"];
type FetchPathOptions = FetchParameters["path"];
type FetchQueryOptions = FetchParameters["query"];

type AlertsAggregateQueryOptions =
  operations["get_alert_company_groups_api_v1_admin_alert_groups_aggregate_get"]["parameters"]["query"];

type AlertGroupedQueryOptions =
  operations["get_all_alerts_api_v1_admin_alert_groups_aggregate_alerts_get"]["parameters"]["query"];

type PathGroupTypeOptions =
  operations["get_alert_type_api_v1_admin_alert_groups_type__alert_type_id__get"]["parameters"]["path"];

type AlertTimelineQueryOptions =
  operations["get_alert_timeline_api_v1_admin_alert_groups_timeline__alert_type_id__get"]["parameters"]["query"];

type AlertTimelinePathOptions =
  operations["get_alert_timeline_api_v1_admin_alert_groups_timeline__alert_type_id__get"]["parameters"]["path"];

const alertQuery = (options: FetchPathOptions, query: FetchQueryOptions) => {
  return {
    queryKey: [
      "alert",
      options.alert_type_id,
      query.group_key,
      query.company_id,
      query.limit,
      query.skip,
    ],
    queryFn: async () => {
      const { data } = await openapi.get("/api/v1/admin/alert-groups/{alert_type_id}", {
        params: {
          path: options,
          query,
        },
      });

      return data;
    },
    useErrorBoundary: true,
  };
};

const alertTimelineQuery = (
  query: AlertTimelineQueryOptions,
  path: AlertTimelinePathOptions
) => {
  return {
    queryKey: [
      "alert-timeline",
      path.alert_type_id,
      query.company_id,
      query.group_key,
      query.limit,
    ],
    queryFn: async () => {
      const { data } = await openapi.get(
        "/api/v1/admin/alert-groups/timeline/{alert_type_id}",
        {
          params: {
            query,
            path,
          },
        }
      );

      return data;
    },
    useErrorBoundary: true,
  };
};

export const useAlertQuery = (options: {
  typeId: string;
  groupKey?: string;
  companyId?: string;
  limit?: number;
  skip?: number;
}) => {
  return useQuery(
    alertQuery(
      {
        alert_type_id: Number(options.typeId),
      },
      {
        group_key: options.groupKey,
        company_id: options.companyId ? Number(options.companyId) : undefined,
        limit: options.limit ?? 10,
        skip: options.skip ?? 0,
      }
    )
  );
};

export const useAlertTimeline = (options: {
  alert_type_id: string;
  group_key?: string;
  company_id?: string;
  limit?: number;
}) => {
  return useQuery(
    alertTimelineQuery(
      {
        company_id: options.company_id ? Number(options.company_id) : undefined,
        group_key: options.group_key,
        limit: options.limit ?? 10,
      },
      {
        alert_type_id: Number(options.alert_type_id),
      }
    )
  );
};

export const useAlertTypeQuery = (options: { typeId: string }) => {
  return useQuery({
    queryKey: ["alert/type", options.typeId],
    queryFn: async () => {
      const { data } = await openapi.get(
        "/api/v1/admin/alert-groups/type/{alert_type_id}",
        {
          params: {
            path: { alert_type_id: Number(options.typeId) },
          },
        }
      );

      return data;
    },
    useErrorBoundary: true,
  });
};

const alertsAggregateQuery = (
  query: operations["get_alert_company_groups_api_v1_admin_alert_groups_aggregate_get"]["parameters"]["query"]
) => ({
  queryKey: ["alert-groups-aggregate", JSON.stringify(query)],
  queryFn: async () => {
    const { data } = await openapi.get("/api/v1/admin/alert-groups/aggregate", {
      params: {
        query,
      },
    });

    return data;
  },
});

export const useAlertsAggregate = (options: AlertsAggregateQueryOptions) => {
  return useQuery(alertsAggregateQuery(options));
};

export const useAlertsAggregateMeta = () => {
  return useQuery({
    queryKey: ["alert-groups-aggregate-meta"],
    queryFn: () =>
      openapi
        .get("/api/v1/admin/alert-groups/aggregate/meta", {
          params: {},
        })
        .then((res) => res.data),
  });
};

const alertGroupTypeQuery = (options: PathGroupTypeOptions) => {
  return {
    queryKey: ["alert/type", options.alert_type_id],
    queryFn: async () => {
      const { data } = await openapi.get(
        "/api/v1/admin/alert-groups/type/{alert_type_id}",
        {
          params: {
            path: options,
          },
        }
      );

      return data;
    },
    useErrorBoundary: true,
  };
};

const fetchAlertGrouped = async (query: AlertGroupedQueryOptions) => {
  const { data } = await openapi.get("/api/v1/admin/alert-groups/aggregate/alerts", {
    params: {
      query,
    },
  });

  return data;
};

const alertGroupedQuery = (query: AlertGroupedQueryOptions) => {
  return {
    queryKey: [
      "alert-group",
      query.company_id,
      query.type_id,
      query.limit,
      query.skip,
      query.sort,
    ],
    queryFn: () => fetchAlertGrouped(query),
    enabled: !!query.type_id || !!query.company_id,
    useErrorBoundary: true,
  };
};

export const alertGroupLoader = (queryClient: QueryClient) => {
  return async ({ params }) => {
    const query = alertGroupTypeQuery({ alert_type_id: params.typeId });

    return (
      queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
    );
  };
};

export const useAlertGrouped = (query: AlertGroupedQueryOptions) => {
  return useQuery(alertGroupedQuery(query));
};
