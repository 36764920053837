import debounce from "lodash/debounce";
import { twMerge } from "tailwind-merge";
import { Dialog, Transition } from "@headlessui/react";
import { useLocation } from "react-router-dom";
import { Loading } from "~/components";
import Banner from "~/components/banner";
import { useBanner } from "~/components/dashboard/context/banner-context";
import UserHijack from "~/components/super-admin/user-hijack";
import {
  Fragment,
  createContext,
  Suspense,
  useCallback,
  useContext,
  useState,
} from "react";
import Sidebar from "./desktop-sidebar";
import Navbar from "./navbar";
import PhoneSidebar from "./phone-sidebar";

const SidebarContext = createContext({});
export const useSidebar = () => useContext(SidebarContext);

const SidebarContextProvider = ({ children }) => {
  const [showPhoneSidebar, setShowPhoneSidebar] = useState(false);
  const [showDesktopSidebar, setShowDesktopSidebar] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedPhoneUpdate = useCallback(
    debounce((state) => {
      setShowPhoneSidebar(state);
    }, 50),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedDesktopUpdate = useCallback(
    debounce((state) => {
      setShowDesktopSidebar(state);
    }, 50),
    []
  );

  return (
    <SidebarContext.Provider
      value={{
        phone: {
          shouldShowSidebar: showPhoneSidebar,
          showSidebar: debouncedPhoneUpdate,
        },
        desktop: {
          shouldShowSidebar: showDesktopSidebar,
          showSidebar: debouncedDesktopUpdate,
        },
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

const DashboardLayout2 = ({
  children,
  className,
  navigation,
  variant = "employer",
  name,
}) => {
  const { phone, desktop } = useSidebar();
  const { state: bannerStateQueue } = useBanner();
  const bannerState = bannerStateQueue[0];
  const { pathname } = useLocation();
  const showBanner =
    bannerState &&
    (bannerState.isGlobal
      ? !!`${bannerState.message}`.length
      : pathname === bannerState.pageRoute && !!`${bannerState.message}`.length);

  return (
    <div className={className}>
      <Transition.Root show={phone.shouldShowSidebar} as={Fragment}>
        <Dialog
          as="div"
          className={`${className} fixed inset-0 z-40 flex md:hidden`}
          onClose={phone.showSidebar}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-30"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-30"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-secondary-main" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex w-full max-w-[200px] flex-1 flex-col bg-navbar-bg pb-4">
              <PhoneSidebar navigation={navigation} setSidebarOpen={phone.showSidebar} />
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div
        className={twMerge(
          "hidden transition-transform delay-[0ms] duration-300 ease-in-out sm:fixed sm:inset-y-0 sm:flex sm:w-[48px] sm:flex-col lg:w-[200px]",
          desktop.shouldShowSidebar ? "" : "-translate-x-full"
        )}
      >
        <Sidebar variant={variant} navigation={navigation} name={name} />
      </div>

      <Navbar setSidebarOpen={phone.showSidebar} />

      <div className="flex min-h-screen flex-1 flex-grow flex-col pb-5 sm:pl-[48px] lg:pl-[200px] bg-layout-bg">
        {showBanner && <Banner variant={variant} />}
        <main className="mt-12 flex max-w-[74rem] flex-1 flex-grow flex-col px-8 pb-5 ">
          <UserHijack />
          <Suspense fallback={<Loading fullScreen />}>{children}</Suspense>
        </main>
      </div>
    </div>
  );
};

export default function DashboardLayoutWithContext(props) {
  return (
    <SidebarContextProvider>
      <DashboardLayout2 {...props} />
    </SidebarContextProvider>
  );
}
