export const QuestionIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17ZM9.31802 13.4176C9.12202 13.6136 9.02402 13.8516 9.02402 14.1316C9.02402 14.4116 9.12202 14.6542 9.31802 14.8596C9.51402 15.0556 9.76136 15.1536 10.06 15.1536C10.3587 15.1536 10.606 15.0556 10.802 14.8596C10.998 14.6542 11.096 14.4116 11.096 14.1316C11.096 13.8516 10.998 13.6136 10.802 13.4176C10.606 13.2122 10.3587 13.1096 10.06 13.1096C9.76136 13.1096 9.51402 13.2122 9.31802 13.4176ZM8.60402 6.89358C9.01469 6.68825 9.43936 6.58558 9.87802 6.58558C10.3634 6.58558 10.7647 6.71158 11.082 6.96358C11.3994 7.21558 11.558 7.52824 11.558 7.90158C11.558 8.46158 11.2127 9.01225 10.522 9.55358C10.0834 9.88958 9.75202 10.2349 9.52802 10.5896C9.30402 10.9349 9.19202 11.2709 9.19202 11.5976V12.0876H10.83V11.7516C10.83 11.5929 10.8674 11.4436 10.942 11.3036C11.026 11.1636 11.1287 11.0329 11.25 10.9116C11.3714 10.7809 11.502 10.6549 11.642 10.5336C11.782 10.4122 11.9127 10.2956 12.034 10.1836C12.454 9.79158 12.7574 9.40425 12.944 9.02158C13.1307 8.63891 13.224 8.21425 13.224 7.74758C13.224 7.35558 13.14 6.99625 12.972 6.66958C12.8134 6.33358 12.5894 6.04425 12.3 5.80158C12.0107 5.55891 11.6654 5.36758 11.264 5.22758C10.872 5.08758 10.4474 5.01758 9.99002 5.01758C9.48602 5.01758 9.01469 5.11091 8.57602 5.29758C8.14669 5.47491 7.75469 5.71291 7.40002 6.01158V7.77558C7.79202 7.38358 8.19336 7.08958 8.60402 6.89358Z"
      fill="#8C8879"
    />
  </svg>
);
